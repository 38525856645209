'use client';

import type { WidgetTeaserItemProps } from '../../../../../types/cms-teaser';
import { AdInsideList } from '../../../../UI/AdInsideList/AdInsideList';
import { TeaserGridSection } from '../../../../UI/TeaserGridSection';
import type { TitleLink } from '../../../../UI/Title/Title';

export type WidgetTeaserAufmacherProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserAufmacher = ({ context }: { context: WidgetTeaserAufmacherProps }) => {
  const width = [1200, 375];

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      <AdInsideList context={context} teaserImageWidth={width[1]} leadTeaserImageWidth={width[0]} offset={-1} />
    </TeaserGridSection>
  );
};

export default WidgetTeaserAufmacher;
