'use client';
import { useEffect, useRef, useState } from 'react';
import { videoEvents } from '../../../../../utils/video';
import VideoPlayer from '../../../../UI/VideoPlayer/VideoPlayer';
import type { VideoProps } from '../../../../UI/VideoPlayer/VideoPlayerDefault';
import { VideoSwiper } from '../../../../UI/VideoSwiper';
import styles from './widget-teaser-playlist.module.scss';

export type WidgetTeaserVideoPlaylistProps = {
  readonly items?: Array<{
    readonly model: VideoProps;
  }>;
};

const WidgetTeaserPlaylist = ({ context }: { context: WidgetTeaserVideoPlaylistProps }) => {
  const [isClient, setIsClient] = useState<boolean>(false);
  const videoList = context?.items?.map((item) => item.model) as Array<VideoProps>;
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
  const [isAdPlaying, setIsAdPlaying] = useState<boolean>(false);
  const startType = useRef(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [currentAdTime, setCurrentAdTime] = useState<number>(0);
  const [totalClips, setTotalClips] = useState<number>(0);

  const currentVideo = currentVideoIndex === -1 ? undefined : videoList[currentVideoIndex];

  useEffect(() => setIsClient(true), []);

  return (
    videoList &&
    isClient && (
      <div className={styles.playlist}>
        {currentVideo && (
          <VideoPlayer
            video={currentVideo}
            noPostroll
            onEvents={videoEvents({
              setIsAdPlaying,
              setTotalClips,
              setTotalDuration,
              setCurrentAdTime,
            })}
            startType={startType.current}
          />
        )}
        <p className={styles.counter}>Playlist: {videoList.length} Videos</p>
        <VideoSwiper
          onClick={(index: number) => {
            startType.current = 2;
            setCurrentVideoIndex(index);
          }}
          videoList={videoList}
          isAdPlaying={isAdPlaying}
          totalAdClips={totalClips}
          totalAdDuration={totalDuration}
          currentAdTime={currentAdTime}
        />
      </div>
    )
  );
};

export default WidgetTeaserPlaylist;
