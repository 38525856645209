'use client';
import type { WidgetTeaserStandardProps } from '../../../../../types/cms-teaser';
import { AdInsideList } from '../../../../UI/AdInsideList/AdInsideList';
import { TeaserGridSection } from '../../../../UI/TeaserGridSection';

const WidgetTeaserStandard = ({
  context,
  isSponsored,
  gridColumns,
}: {
  context: WidgetTeaserStandardProps;
  isSponsored?: boolean;
  gridColumns?: 2 | 3 | 5;
}) => {
  const width = 375;
  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      isSponsored={isSponsored}
      gridColumns={gridColumns}
      anchor={context.model?.fields?.anchor}
    >
      <AdInsideList context={context} teaserImageWidth={width} />
    </TeaserGridSection>
  );
};

export default WidgetTeaserStandard;
