import type { AdTimeChangedPlayerEvent, ExtendedAdSlotStartPlayerEvent, PlayerEvent } from '../types/video';

export interface CbcPlayerOnEvent {
  setIsAdPlaying: (isAdPlaying: boolean) => void;
  setTotalClips: (totalClips: number) => void;
  setTotalDuration: (totalDuration: number) => void;
  setCurrentAdTime: (currentAdTime: number) => void;
}

export const videoEvents =
  ({ setIsAdPlaying, setTotalDuration, setCurrentAdTime, setTotalClips }: CbcPlayerOnEvent) =>
  (event: PlayerEvent) => {
    if (event.type === 'onAdSlotStart') {
      setIsAdPlaying(true);
    }

    if (event.type === 'onAdSlotComplete') {
      setIsAdPlaying(false);
    }

    if (event.type === 'onExtendedAdStarted') {
      const adSlotEvent = event as unknown as ExtendedAdSlotStartPlayerEvent;
      let totalDuration = 0;
      for (const clip of adSlotEvent.adSlot.clips) {
        if (clip.state !== 'FINISHED') {
          totalDuration += Math.round(clip.info.duration);
        }
      }
      setTotalClips(adSlotEvent.adSlot.clips[0].info.totalClips);
      setCurrentAdTime(0);
      setTotalDuration(totalDuration);
    }

    if (event.type === 'onAdTimeChanged') {
      setCurrentAdTime((event as unknown as AdTimeChangedPlayerEvent).time);
    }
  };
