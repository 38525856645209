'use client';
import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { generateWeakKey } from '../../../../../utils/idGenerator';
import type { ApiImageProps } from '../../../../UI/Image/Image';
import Image from '../../../../UI/Image/Image';
import Link from '../../../../UI/Link/Link';
import { Title } from '../../../../UI/Title';
import type { TitleLink } from '../../../../UI/Title/Title';
import { Typography } from '../../../../UI/Typography';
import styles from './widget-teaser-rtl-plus-letzte-folgen.module.scss';

export type WidgetTeaserRtlPlusLastEpisodesProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
    };
  };
  readonly items?: ReadonlyArray<{
    readonly model: {
      readonly id: string;
      readonly fields: {
        readonly title: string;
        readonly 'short-text'?: string;
        readonly 'start-date'?: string;
        readonly url: string;
        readonly 'is-premium'?: boolean | string;
      };
      readonly image?: ApiImageProps;
    };
  }>;
};

const WidgetTeaserRtlPlusLastEpisodes = ({ context }: { context: WidgetTeaserRtlPlusLastEpisodesProps }) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [activeIndicator, setActiveIndicator] = useState(0);

  const width = 440;

  const handleScroll = () => {
    const gridCurrentRef = gridRef.current;

    if (gridCurrentRef !== null) {
      const { scrollLeft, children } = gridCurrentRef;
      const teaserWidth = children[0].clientWidth;
      const activeIndex = Math.round(scrollLeft / teaserWidth);

      setActiveIndicator(activeIndex);
    }
  };

  useEffect(() => {
    const gridCurrentRef = gridRef.current;

    gridCurrentRef?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      gridCurrentRef?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={styles.section} id={context.model?.fields?.anchor}>
      {!!context.model?.fields?.title && (
        <Title link={context.model?.fields?.link}>{context.model?.fields?.title}</Title>
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.grid} ref={gridRef}>
          {context.items?.map((item, index) => (
            <div key={item.model.id} className={clsx(styles.teaser, { [styles.first]: index === 0 })}>
              <div className={styles.imageWrapper}>
                <Image image={item.model.image} className={styles.image} alt={item.model.fields.title} width={width} />
                <Typography className={styles.badge} variant="4xs" as="div">
                  {item.model.fields['is-premium'] === 'true' ? 'Premium' : 'Free'}
                </Typography>
              </div>
              <Link className={styles.link} href={item.model.fields.url} target="_blank" noFollow={false}>
                <div className={styles.textWrapper}>
                  {!!item.model.fields['start-date'] && (
                    <Typography className={styles.date} variant="4xs" as="div">
                      {new Date(item.model.fields['start-date']).toLocaleDateString('de-DE', {
                        weekday: 'short',
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </Typography>
                  )}
                  <Typography className={styles.headline} as="h3" variant={index === 0 ? 's' : '3xs'}>
                    {item.model.fields.title}
                  </Typography>
                  <Typography className={styles.description} variant="4xs">
                    {item.model.fields['short-text']}
                  </Typography>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className={styles.indicatorWrapper}>
          {context.items?.map((item, index) => (
            <div
              key={`${generateWeakKey(item, 'teaser-rtl-plus-letzte-folgen-indicator')}`}
              className={clsx(styles.indicator, { [styles.active]: activeIndicator === index })}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WidgetTeaserRtlPlusLastEpisodes;
